import React from 'react';
import CheckOrange from 'components/icons/CheckOrange';
import Slider from 'react-slick';
import cn from 'classnames';
import BenefitsImage from 'images/landing-benefits.png';
import LandingFood from 'images/landing-food.svg';
import LandingQuick from 'images/landing-quick.svg';
import LandingApple from 'images/landing-apple.svg';

type ItemWrapperProps = {
  title: string;
  image: string | undefined;
  mobile?: boolean;
};

const Title = ({ text }: { text: string }) => (
  <div className="text-3xl text-orange-50 font-bold mb-5 lg:mb-0 lg:flex-grow-1 sm:text-lg">{text}</div>
);

const ItemWrapper: React.FC<ItemWrapperProps> = ({ title, image, children, mobile }) => (
  <div className="flex items-start mt-9 lg:flex-col lg:mt-0 mb-5">
    <div className="mr-9 mt-8 w-20 h-20 flex-shrink-0 lg:flex lg:w-full lg:items-center sm:w-full sm:h-auto lg:mb-2.5">
      <img src={image} alt={title} className=" w-full h-full lg:w-10 lg:h-10 lg:mr-3" />
      {mobile && <Title text={title} />}
    </div>
    <div>
      {!mobile && <Title text={title} />}
      <div className="text-lg sm:text-sm">{children}</div>
    </div>
  </div>
);

const ListWrapper: React.FC = ({ children }) => {
  const [mobile, setMobile] = React.useState<boolean>(false);
  const [activeSlide, setActiveSlide] = React.useState<number>(1);
  const handleWidth = () => {
    if (window.innerWidth > 1040) setMobile(false);
    else setMobile(true);
  };
  React.useLayoutEffect(() => {
    handleWidth();
    window.addEventListener('resize', handleWidth);
    return () => {
      window.removeEventListener('resize', handleWidth);
    };
  }, []);

  if (mobile) {
    return (
      <Slider
        afterChange={(slide) => setActiveSlide(slide)}
        customPaging={(i) => (
          <div
            className={cn(
              `${i === activeSlide ? 'opacity-100' : 'opacity-25'}`,
              'w-2.5 h-2.5 bg-orange-50 rounded-full',
            )}
          />
        )}
        appendDots={(dots) => <div className="mt-2">{dots}</div>}
        slidesToShow={1}
        dots
        adaptiveHeight={false}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { mobile: true });
          }
          return child;
        })}
      </Slider>
    );
  }
  return <>{children}</>;
};

export const LandingBenefits = () => (
  <div className="px-2 flex items-center mb-20 lg:flex-col-reverse">
    <div className="w-1/2 mr-20 lg:w-full lg:mr-0">
      <ListWrapper>
        <ItemWrapper title="Качество" image={LandingFood}>
          <div className="mb-1">Мы осуществляем контроль качества на всех этапах:</div>
          <div className="flex items-center">
            <CheckOrange size={15} className="mr-3.5" />
            <span>выбираем проверенных поставщиков</span>
          </div>
          <div className="flex items-center">
            <CheckOrange size={15} className="mr-3.5" />
            <span>вручную отбираем овощи, фрукты и ягоды</span>
          </div>
          <div className="flex items-center mb-1">
            <CheckOrange size={15} className="mr-3.5" />
            <span>безукоризненно следим за условиями доставки заказа</span>
          </div>
          <div>А если вдруг качество товара вас не устроит, мы заменим его или вернём деньги.</div>
        </ItemWrapper>
        <ItemWrapper title="Быстро" image={LandingQuick}>
          Время — самая большая ценность. Не тратьте его на поход в магазин. Доверьте выбор натуральных продуктов нам.
          Бережно доставим ваш заказ в течение 2 часов.
        </ItemWrapper>
        <ItemWrapper title="С любовью" image={LandingApple}>
          Мы постоянно развиваемся и становимся лучше — находимся на связи каждый день с 7 утра до 10 вечера и быстро
          решаем любые вопросы, всегда ищем новые товары, прислушиваемся к вашему мнению и используем эко-упаковку.
        </ItemWrapper>
      </ListWrapper>
    </div>
    <div className="w-1/2 lg:w-full">
      <img src={BenefitsImage} alt="Преимущества" className="lg:mx-auto" />
    </div>
  </div>
);
