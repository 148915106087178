import * as React from 'react';
import PhoneImage from 'images/landing-phone.png';
import { StoreLinks } from './StoreLinks';

export const LandingApps = () => (
  <div className="pt-28 mb-20 md:pt-5 sm:pt-0">
    <div className="bg-gray-400 rounded-[30px] max-w-[890px] mx-auto flex items-center relative h-[300px] p-5 md:h-[230px] sm:h-auto sm:max-w-[400px] sm:flex-col-reverse ">
      <div className="absolute bottom-0 left-[-75px] md:left-[-20px] md:max-w-[400px] sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:max-w-auto sm:w-[350px]">
        <img src={PhoneImage} alt="Телефон" />
      </div>
      <div className="w-full max-w-[450px] relative z-10 ml-auto md:max-w-[360px] sm:ml-0 sm:w-auto sm:pb-[250px]">
        <div className="text-[40px] font-bold leading-[52px] md:text-3xl sm:text-[26px] ">
          Скачивайте наше <br /> удобное приложение
        </div>
        <StoreLinks className="mt-6" />
      </div>
    </div>
  </div>
);
