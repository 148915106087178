import { YMapWithDeliveryZone_Data } from 'generated/graphql';
import * as React from 'react';
import { YMapWithDeliveryZone } from './YMapWithDeliveryZone';

type Props = {
  data: YMapWithDeliveryZone_Data;
};

const blocks = [
  {
    title: 'Не только быстро, но и качественно',
    content: (
      <>
        Мы собираем заказы, строго соблюдая принципы товарного соседства. Для поддержания необходимого температурного
        режима используем термоизоляционные пакеты. Автомобили наших курьеров оборудованы профессиональными холодильными
        и морозильными камерами, а пешеходные курьеры доставляют заказы в терморюкзаках.
      </>
    ),
  },
  {
    title: 'Внимание к деталям',
    content: (
      <>
        Дома спит малыш? Понадобится сдача? Заказ нужно оставить у консьержа? Оставьте комментарий к заказу, и курьер
        учтёт ваши пожелания.
      </>
    ),
  },
  {
    title: 'Когда всё пошло не по плану',
    content: (
      <>
        Вы всегда можете перенести дату доставки или отменить заказ. Для этого достаточно обратиться в службу работы с
        клиентами по телефону <span className="text-orange-50 font-black whitespace-nowrap">+7 (495) 419-95-54</span>
      </>
    ),
  },
];

const DeliveryInfo = ({ className = '' }: { className?: string }) => (
  <div className={`text-xl mb-[25px] sm:text-lg ${className}`}>
    Мы доставляем заказы по всей Москве и ближайшему Подмосковью с{' '}
    <span className="text-orange-50 font-black">8:00 до 22:00</span>
  </div>
);

export const LandingDelivery = ({ data }: Props) => (
  <div className="lg:px-2 mb-20">
    <div className="text-[40px] font-bold mb-10 lg:text-3xl sm:text-[26px] lg:mb-4">Зона доставки</div>
    <DeliveryInfo className="hidden 2xl:block sm:mb-4" />
    <div className="flex 2xl:flex-col-reverse">
      <div className="w-full max-w-[537px] pr-2 flex-shrink-0 2xl:pr-0 2xl:max-w-[1040px]">
        <DeliveryInfo className="2xl:hidden" />
        {blocks.map(({ title, content }) => (
          <div key={title} className="mb-5">
            <div className="font-bold text-xl mb-5 sm:mb-3 sm:text-lg">{title}</div>
            <div className="sm:text-sm">{content}</div>
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col font-medium text-orange-50 pl-2 2xl:pl-0 2xl:mb 2xl:mb-6 2xl:flex-col-reverse">
        <div className="mb-5 sm:mb-0">
          <YMapWithDeliveryZone data={data} />
        </div>
        <div className="flex -mx-2.5 2xl:-mx-1.5 2xl:mb-6 sm:flex-col sm:mx-0">
          {[
            <div>
              Интервал доставки <br /> 2 часа
            </div>,
            <div>
              Бесплатная доставка при <br /> первом заказе
            </div>,
            <div>
              Минимальная сумма <br /> заказа 2000 руб
            </div>,
          ].map((i, idx) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className="bg-orange-50 bg-opacity-5 w-1/3 py-[18px] text-[20px] text-center rounded-[10px] mx-2.5 2xl:mx-1.5 sm:mx-0 2xl:text-lg 2xl:px-8 sm:py-3.5 sm:w-full sm:mb-2"
            >
              {i}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
