import * as React from 'react';
import OneIcon from 'images/landing-poss-one.svg';
import TwoIcon from 'images/landing-poss-two.svg';
import ThreeIcon from 'images/landing-poss-three.svg';
import OrderImage from 'images/landing-poss-order.png';
import PickImage from 'images/landing-poss-pick.png';
import DeliveryImage from 'images/landing-poss-deliv.png';

const blocks = [
  {
    numberIcon: OneIcon,
    title: 'Закажите продукты',
    image: OrderImage,
    text: 'Выбор и акции - как в магазине, время доставки - как вам удобно',
  },
  {
    numberIcon: TwoIcon,
    title: 'Пикер соберет заказ',
    image: PickImage,
    text: 'Согласует с вами замену, если что-то закончилось',
  },
  {
    numberIcon: ThreeIcon,
    title: 'Курьер привезет',
    image: DeliveryImage,
    text: 'Проверьте заказ, а потом оплатите',
  },
];

type ItemProps = {
  numberIcon: string;
  title: string;
  image: string;
  text: string;
};

const Item = ({ numberIcon, title, image, text }: ItemProps) => (
  <div className="pr-4 w-1/3 xl:w-[440px] sm:w-full md:pr-0 sm:pb-3">
    <div className="flex items-center mb-4 sm:mb-3">
      <img src={numberIcon} alt="one" className="mr-2 sm:h-[47px]" />
      <span className="text-[25px] text-orange-50 font-medium sm:text-lg">{title}</span>
    </div>
    <div className="bg-orange-50 bg-opacity-5 flex text-lg rounded-[20px]">
      <img src={image} alt="Заказ" className="sm:w-[109px]" />
      <div className="p-7 sm:text-[14px] sm:p-5 sm:leading-[150%]">{text}</div>
    </div>
  </div>
);

export const LandingPossibility = () => (
  <div className="my-10 px-2 mb-20">
    <div className="max-w-[830px] text-3xl font-bold mt-5 mb-[40px] sm:text-lg sm:mb-3.5">
      Вы заказываете продукты, а мы собираем и привозим их в удобное вам время
    </div>
    <div className="flex xl:flex-wrap justify-around md:justify-start md:flex-col md:items-center">
      {blocks.map((item) => (
        <Item key={item.title} {...item} />
      ))}
    </div>
  </div>
);
