import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { gql } from 'urql';
import { Control, Controller, useForm, Resolver, FieldErrors, FieldError } from 'react-hook-form';
import { normalizePhone, validateEmail } from 'utils';
import { useStoreon } from 'hooks';
import { useLandingMainMutation } from 'generated/graphql';
import LandingMainImg from 'images/landing-main.png';
import LandingArrowRight from './icons/LandingArrow';
import CheckIcon from './icons/CheckOrange';
import { PhoneInputWithMask } from './PhoneInputWithMask';
import DotLoader from './icons/DotLoader';

type FormValues = {
  phone: string;
  email: string;
};

type FormProps = {
  readonly control: Control<FormValues>;
  readonly onSubmit: () => void;
  readonly check: boolean;
  readonly setCheck: () => void;
  readonly errors: FieldErrors<FormValues>;
  readonly loading?: boolean;
  readonly promoCode?: string | null;
};

const Form = ({ control, onSubmit, check, setCheck, errors, loading, promoCode }: FormProps) => {
  if (promoCode) {
    return (
      <div className="text-lg">
        Ваш промокод: <span className="font-medium">{promoCode}</span>
      </div>
    );
  }

  if (loading) {
    return <DotLoader style={{ transform: 'scale(2)' }} className="inline-block" />;
  }

  return (
    <>
      <form onSubmit={onSubmit} className="flex items-center sm:flex-col">
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange } }) => (
            <PhoneInputWithMask
              className={cn(
                errors.phone?.message ? 'border bg-[#FFECFC] text-black border-[#FF5757]' : '',
                'border border-transparent hover:border-orange-50 focus:border-orange-50 transition-all w-full max-w-[227px] h-12 px-4 py-3 placeholder-gray-50 bg-gray-100',
              )}
              errorMessageClassName="font-semibold text-[#FF5757] mt-1 sm:hidden"
              onChange={onChange}
              errorMessage={errors.phone?.message}
              placeholder="Номер телефона"
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <div className="relative ml-4 sm:ml-0 sm:mt-2">
              <input
                type="text"
                placeholder="Email"
                onChange={onChange}
                value={value}
                className={cn(
                  errors.email?.message ? 'border-[#FF5757] bg-[#FFECFC] text-black' : '',
                  'w-full max-w-[227px] h-12 px-4 py-3  rounded-[4px] border border-transparent hover:border-orange-50 focus:border-orange-50 transition-all placeholder-gray-50 bg-gray-100',
                )}
              />
              {errors.email?.message && (
                <div className="absolute top-full mt-1 text-sm font-semibold text-[#FF5757] sm:hidden">
                  {errors.email?.message}
                </div>
              )}
            </div>
          )}
        />

        <button
          type="submit"
          style={{ padding: 0, height: 48 }}
          className="bg-orange-50 text-white rounded-[4px] font-medium hover:bg-[#FA733A] transition-colors duration-200 ml-4 w-[200px] flex items-center justify-center !flex-shrink-0 sm:ml-0 sm:mt-2 sm:w-full"
        >
          <span className="sm:hidden">Получить</span>
          <span className="hidden sm:block">
            <LandingArrowRight />
          </span>
        </button>
      </form>
      <div className="text-sm flex items-center mt-8">
        <button
          type="button"
          onClick={setCheck}
          className="w-5 h-5 border flex items-center justify-center flex-shrink-0 border-gray-50 rounded-sm mr-2.5 hover:border-black transition-all cursor-pointer"
        >
          <CheckIcon size={14} className={`${!check ? 'opacity-0' : 'opacity-100'}  transition-opacity`} />
        </button>
        <div>
          Нажимая на кнопку, я соглашаюсь с&nbsp;
          <Link to="/agreement" className="font-bold underline">
            условиями сервиса
          </Link>
        </div>
      </div>
    </>
  );
};

type Validator = (values: FormValues) => ErrorData | null;

type ErrorData = {
  field: keyof FormValues;
  error: FieldError;
};

const validators: Validator[] = [
  (values) => {
    if (normalizePhone(values.phone).length !== 11) {
      return {
        field: 'phone',
        error: {
          type: 'required',
          message: 'Некорректный номер',
        },
      };
    }
    return null;
  },
  (values) => {
    if (!validateEmail(values.email)) {
      return {
        field: 'email',
        error: {
          type: 'required',
          message: 'Некорректный email',
        },
      };
    }
    return null;
  },
];

const resolver: Resolver<FormValues> = async (values) => {
  const errorData: ErrorData[] = [];
  validators.forEach((v) => {
    const ed = v(values);
    if (ed) {
      errorData.push(ed);
    }
  });

  if (errorData.length > 0) {
    const errors: FieldErrors<FormValues> = {};
    errorData.forEach((e) => {
      errors[e.field] = e.error;
    });
    return { errors, values: {} };
  }

  return { values, errors: {} };
};

export const LandingMain = () => {
  const [check, setCheck] = React.useState<boolean>(false);
  const { dispatch, promoCode } = useStoreon('promoCode');

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: { phone: '', email: '' },
    resolver,
  });
  const { errors } = formState;

  const [res, mutation] = useLandingMainMutation();

  const onSubmit = handleSubmit(async (values) => {
    if (!check) {
      // eslint-disable-next-line no-alert
      return window.alert('Вы должны согласиться с условиями сервиса');
    }
    try {
      const updateRes = await mutation({ input: values });

      if (updateRes.error) {
        throw updateRes.error;
      }
      if (updateRes.data?.requestFirstOrderPromoCode.__typename === 'ErrorPayload') {
        throw new Error(updateRes.data.requestFirstOrderPromoCode.message);
      }
      if (updateRes.data?.requestFirstOrderPromoCode.__typename === 'RequestFirstOrderPromoCodePayload') {
        dispatch('setPromoCode', updateRes.data.requestFirstOrderPromoCode.promoCode);
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(e.message);
    }
    return null;
  });

  return (
    <div className="flex items-center justify-between pl-2 pr-10 lg:flex-col-reverse lg:pr-5 lg:pl-5 mb-20">
      <div className="w-1/2 pr-14 lg:w-full lg:pr-0 lg:flex lg:flex-col lg:items-center">
        <h2 className="text-[66px] leading-[77px] font-bold max-w-[478px] mb-10 lg:text-center lg:text-3xl md:text-2xl sm:flex sm:flex-col sm:mb-4">
          Доставка <span>FAMILY FRIEND!</span>
        </h2>
        <div className="max-w-[513px] text-2xl mb-10 lg:text-center sm:text-base sm:leading-4 sm:mb-4">
          Я хочу <span className="text-orange-50 font-medium">промокод на скидку</span> и ссылку на скачивание
          приложения
        </div>
        <Form
          control={control}
          onSubmit={onSubmit}
          check={check}
          setCheck={() => setCheck((s) => !s)}
          errors={errors}
          loading={res.fetching}
          promoCode={promoCode}
        />
      </div>
      <div className="w-1/2 flex items-center justify-center lg:w-full lg:mb-10 ">
        <img src={LandingMainImg} alt="description" />
      </div>
    </div>
  );
};

gql`
  mutation LandingMainMutation($input: RequestFirstOrderPromoCodeInput!) {
    requestFirstOrderPromoCode(input: $input) {
      ... on ErrorPayload {
        message
      }
      ... on RequestFirstOrderPromoCodePayload {
        promoCode
      }
    }
  }
`;
