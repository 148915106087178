import * as React from 'react';
import BasketIcon from 'images/landing-basket.svg';
import FoodServingIcon from 'images/landing-food-serving.svg';
import DeliveryIcon from 'images/landing-delivery.svg';
import AwardIcon from 'images/landing-award.svg';

const iconClassNames = 'mb-[25px] lg:h-[100px] lg:w-[100px] md:w-20 md:h-20';

const blocks = [
  {
    title: 'Для любого повода и просто так',
    icon: <img src={BasketIcon} alt="Корзина" className={iconClassNames} />,
  },
  {
    title: 'Всегда найдется что-нибудь интересное',
    icon: <img src={FoodServingIcon} alt="Еда" className={iconClassNames} />,
  },
  {
    title: 'Доставим в тот же день в удобное время',
    icon: <img src={DeliveryIcon} alt="Доставка" className={iconClassNames} />,
  },
  {
    title: 'Исключительно свежие продукты',
    icon: <img src={AwardIcon} alt="Свежесть" className={iconClassNames} />,
  },
];

export const LandingDescription = () => (
  <div className="flex items-center md:flex-wrap justify-center mb-20">
    {blocks.map(({ title, icon }) => (
      <div
        key={title}
        className="w-1/4 p-1.5 h-[341px] rounded-[10px] xl:h-[275px] md:max-w-[250px] md:w-full md:h-[241px] "
      >
        <div className="bg-[#FA58130D] h-full flex flex-col items-center justify-center px-4">
          {icon}
          <div className="text-[#FA5813] max-w-[237px] text-center text-xl leading-[23px] xl:text-lg md:max-w-[217px] sm:text-base sm:leading-[20px]">
            {title}
          </div>
        </div>
      </div>
    ))}
  </div>
);
