import * as React from 'react';
import qs from 'query-string';
import { graphql, PageProps } from 'gatsby';
import { LandingPageQuery } from 'generated/graphql';
import { MainLayout } from 'components/MainLayout';
import { LandingMain } from 'components/LandingMain';
import { LandingApps } from 'components/LandingApps';
import { LandingPossibility } from 'components/LandingPossibility';
import { Footer } from 'components/Footer';
import { LandingDescription } from 'components/LandingDescription';
import { LandingBenefits } from 'components/landingBenefits';
import { LandingDelivery } from 'components/LandingDelivery';
import { nonNullable } from '../utils';

type Props = {
  data: LandingPageQuery;
} & PageProps;

type BlockItem = { [index: string]: string | JSX.Element };

const getContent = (str: string, data: BlockItem, showAll: boolean) => {
  if (showAll) {
    return Object.keys(data).map((i) => ({
      name: i,
      content: data[i],
    }));
  }
  return str
    .split('')
    .map((i) => {
      if (data[i])
        return {
          name: i,
          content: data[i],
        };
      return null;
    })
    .filter(nonNullable);
};

export default ({ data, location }: Props) => {
  const { s } = qs.parse(location.search);

  const str = typeof s === 'string' ? s : '';

  const names: BlockItem = {
    a: <LandingMain />,
    b: <LandingDelivery data={data} />,
    c: <LandingBenefits />,
    // TODO: Вывести категорию(и) со списком продуктов
    // d: <BlockD data={{ homeCategories: data.homeCategories.filter((_, idx) => idx === 1) }} />,
    e: <LandingDescription />,
    f: <LandingApps />,
    g: <LandingPossibility />,
  };

  const blocks = getContent(str, names, str === 'preview');

  return (
    <>
      <MainLayout>
        <div className="container mb-3 mt-28 lg:mt-10">
          {blocks.map((i) => (
            <div key={i.name}>{i.content}</div>
          ))}
        </div>
      </MainLayout>
      <Footer className="mt-10" />
    </>
  );
};

export const gql = graphql`
  query LandingPageQuery {
    ...YMapWithDeliveryZone_data
  }
`;
